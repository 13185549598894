import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import styles from "./UserDetails.module.css";
import { FaEdit, FaCheck, FaTimes } from "react-icons/fa";

const UserDetails = () => {
  const { user, updateUserDetails, changePassword } = useAuth();
  const [editUsername, setEditUsername] = useState(false);
  const [newUsername, setNewUsername] = useState(user?.username);
  const [editRealName, setEditRealName] = useState(false);
  const [newRealName, setNewRealName] = useState(user?.real_name);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [movies, setMovies] = useState([]);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    console.log("User object:", user);
    if (user) {
      setNewUsername(user.username);
      setNewRealName(user.real_name);
    }

    fetch(`https://moviereviewclub.colinskinner.me/api/movies?userId=${user.id}`, {
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched movies:", data);
        setMovies(data);
      })
      .catch((error) => console.error("Error fetching movies:", error));
  }, [user]);

  const handleUpdateUsername = () => {
    setErrorMessage("");
    setFeedbackMessage("");
    console.log("Updating username to:", newUsername);
    updateUserDetails({ username: newUsername, real_name: newRealName }) // Include real_name
      .then(() => {
        setFeedbackMessage("Username has been updated. Please remember this name as you will need it to log in.");
        setEditUsername(false);
      })
      .catch((error) => {
        if (error.message === "Username already in use") {
          setErrorMessage("Username is already in use by someone else.");
        } else {
          console.error("Error updating username:", error);
        }
      });
  };

  const handleUpdateRealName = () => {
    setErrorMessage("");
    setFeedbackMessage("");
    console.log("Updating real name to:", newRealName);
    updateUserDetails({ username: newUsername, real_name: newRealName }) // Ensure username is included
      .then(() => {
        setFeedbackMessage("Your name has been updated");
        setEditRealName(false);
      })
      .catch((error) => {
        if (error.message === "Username already in use") {
          setErrorMessage("Username is already in use by someone else.");
        } else {
          console.error("Error updating name:", error);
        }
      });
  };

  const handleChangePassword = (e) => {
    e.preventDefault();
    setErrorMessage("");
    setFeedbackMessage("");
    if (newPassword !== confirmNewPassword) {
      setErrorMessage("Passwords don't match");
      return;
    }

    changePassword({ currentPassword, newPassword })
      .then(() => {
        setFeedbackMessage("Password updated. Use this next time you log in.");
      })
      .catch((error) => {
        if (error.message === "Wrong Password") {
          setErrorMessage("Wrong Password");
        } else {
          console.error("Error updating password:", error);
        }
      });
  };

  return (
    <div className={styles.userDetailsContainer}>
      <div className={styles.userField}>
        <span>Username: </span>
        {editUsername ? (
          <>
            <input
              type="text"
              value={newUsername}
              onChange={(e) => setNewUsername(e.target.value)}
            />
            <FaCheck onClick={handleUpdateUsername} />
            <FaTimes onClick={() => setEditUsername(false)} />
          </>
        ) : (
          <>
            <span>{user?.username}</span>
            <FaEdit onClick={() => setEditUsername(true)} />
          </>
        )}
      </div>
      <div className={styles.userField}>
        <span>Your Name: </span>
        {editRealName ? (
          <>
            <input
              type="text"
              value={newRealName}
              onChange={(e) => setNewRealName(e.target.value)}
            />
            <FaCheck onClick={handleUpdateRealName} />
            <FaTimes onClick={() => setEditRealName(false)} />
          </>
        ) : (
          <>
            <span>{user?.real_name}</span>
            <FaEdit onClick={() => setEditRealName(true)} />
          </>
        )}
      </div>
      <div className={styles.userField}>
        <span>Your Email: </span>
        <span>{user?.email || "Email not available"}</span>
      </div>
      <div className={styles.changePasswordSection}>
        <h3>Change Password</h3>
        <form onSubmit={handleChangePassword}>
          <div className={styles.passwordField}>
            <label>Current Password</label>
            <input
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
          </div>
          <div className={styles.passwordField}>
            <label>New Password</label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className={styles.passwordField}>
            <label>Confirm New Password</label>
            <input
              type="password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
          </div>
          <button type="submit" className={styles.submitButton}>Submit</button>
        </form>
      </div>
      {feedbackMessage && <div className={styles.feedbackMessage}>{feedbackMessage}</div>}
      {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
      <div className={styles.reviewsSection}>
        <h3>Your Reviews</h3>
        <h4>Go to the Movie Cards page to review the movies</h4>
        <div className={styles.reviewsList}>
          {movies.map((movie) => {
            const userScore = movie.scores.find(score => score.user_id === user.id);
            return (
              <div key={movie.id} className={styles.reviewItem}>
                <span>{movie.title}</span>
                <span>
                  {userScore ? (
                    <StarRating count={userScore.score} readOnly={true} />
                  ) : (
                    "No review"
                  )}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const StarRating = ({ count = 0, readOnly = false }) => {
  const [rating, setRating] = useState(count);

  useEffect(() => {
    setRating(count);
  }, [count]);

  return (
    <div className={styles.starRating}>
      {[...Array(5)].map((_, index) => {
        const starValue = index + 1;
        let starClass = styles.off;
        if (starValue <= Math.ceil(rating)) {
          starClass = starValue <= rating ? styles.on : styles.half;
        }
        return (
          <button
            type="button"
            key={starValue}
            className={`${styles.star} ${starClass}`}
            disabled={readOnly}
          >
            {starClass === (styles.half || styles.off) ? "★" : "★"}
          </button>
        );
      })}
    </div>
  );
};

export default UserDetails;
