import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const AdminRoute = ({ element: Component, ...rest }) => {
    const { isLoggedIn, isAdmin } = useAuth();

    return isLoggedIn && isAdmin ? <Component {...rest} /> : <Navigate to="/movies" />;
};

export default AdminRoute;
