import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import LogoutButton from './LogoutButton';  // Add this import
import './Menu.css'; // Import the CSS styles

const Menu = () => {
    const { isLoggedIn, isAdmin } = useAuth();
    const location = useLocation();

    // Do not show the menu on the login page
    if (!isLoggedIn || location.pathname === '/login') {
        return null;
    }

    return (
        <nav>
            <Link to="/movies">Movie Cards</Link>
            {isLoggedIn && <Link to="/user-details">User Details</Link>}
            {isAdmin && <Link to="/add-movie">Add/Edit Movie</Link>}
            {isAdmin && <Link to="/manage-users">Manage Users</Link>}
            {isLoggedIn && <LogoutButton />}
        </nav>
    );
};

export default Menu;
