import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const RedirectIfLoggedIn = ({ element: Component, ...rest }) => {
    const { isLoggedIn } = useAuth();

    return isLoggedIn ? <Navigate to="/movies" /> : <Component {...rest} />;
};

export default RedirectIfLoggedIn;
