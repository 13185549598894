import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { AuthProvider } from './contexts/AuthContext';
import Menu from "./components/Menu";
import LoginPage from "./components/LoginPage";
import MovieCardsPage from "./components/MovieCardsPage";
import AddMovie from "./components/AddMovie";
import UserDetails from "./components/UserDetails";
import RegisterPage from "./components/RegisterPage";
import ManageUsers from "./components/ManageUsers";
import ProtectedRoute from './components/ProtectedRoute';
import AdminRoute from './components/AdminRoute';
import ForgotPasswordPage from "./components/ForgotPasswordPage";
import ResetPasswordPage from "./components/ResetPasswordPage";
import RedirectIfLoggedIn from './components/RedirectIfLoggedIn';

const App = () => {
    return (
        <Router>
            <AuthProvider>
                <Menu />
                <Routes>
                    <Route path="/" element={<Navigate to="/movies" />} />
                    <Route path="/login" element={<RedirectIfLoggedIn element={LoginPage} />} />
                    <Route path="/register" element={<RedirectIfLoggedIn element={RegisterPage} />} />
                    <Route path="/movies" element={<ProtectedRoute element={MovieCardsPage} />} />
                    <Route path="/add-movie" element={<AdminRoute element={AddMovie} />} />
                    <Route path="/user-details" element={<ProtectedRoute element={UserDetails} />} />
                    <Route path="/manage-users" element={<AdminRoute element={ManageUsers} />} />
                    <Route path="/forgot-password" element={<RedirectIfLoggedIn element={ForgotPasswordPage} />} />
                    <Route path="/reset-password/:token" element={<RedirectIfLoggedIn element={ResetPasswordPage} />} />
                </Routes>
            </AuthProvider>
        </Router>
    );
};

export default App;
