import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(
        JSON.parse(localStorage.getItem('isLoggedIn')) || false
    );
    const [isAdmin, setIsAdmin] = useState(
        JSON.parse(localStorage.getItem('isAdmin')) || false
    );
    const [user, setUser] = useState(
        JSON.parse(localStorage.getItem('user')) || null  // Store user object in local storage
    );

    useEffect(() => {
        console.log("Auth state changed: ", { isLoggedIn, isAdmin, user });
        localStorage.setItem('isLoggedIn', JSON.stringify(isLoggedIn));
        localStorage.setItem('isAdmin', JSON.stringify(isAdmin));
        localStorage.setItem('user', JSON.stringify(user));
    }, [isLoggedIn, isAdmin, user]);

    const API_URL = "https://moviereviewclub.colinskinner.me/api"; // Update this URL if necessary

    const login = async (username, password) => {
        try {
            const response = await fetch(`${API_URL}/login`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({username, password})
            });
            const data = await response.json();
            if (data.success) {
                setIsLoggedIn(true);
                setIsAdmin(data.isAdmin === 1); // Convert isAdmin to boolean
                setUser(data.user);
                localStorage.setItem('isLoggedIn', 'true');
                localStorage.setItem('isAdmin', JSON.stringify(data.isAdmin === 1)); // Store as boolean
                localStorage.setItem('user', JSON.stringify(data.user));
            } else {
                throw new Error(data.message || "Failed to login");
            }
        } catch (error) {
            console.error("Login error:", error.message);
            alert("Login failed: " + error.message);
        }
    };

    const logout = () => {
        setIsLoggedIn(false);
        setIsAdmin(false);
        setUser(null);
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('isAdmin');
        localStorage.removeItem('user');
    };

    const updateUserDetails = async (details) => {
        console.log("Updating user details with:", details); // Log details
        const response = await fetch(`'https://moviereviewclub.colinskinner.me/api/users/update-details/${user.id}`, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(details),
        });
      
        if (!response.ok) {
          const errorResponse = await response.json();
          console.error("Response not OK:", errorResponse); // Log the error response
          const error = new Error("Error updating user details");
          error.status = response.status;
          throw error;
        }
      
        const updatedUser = await response.json();
        setUser((prevUser) => ({
          ...prevUser,
          ...details,
        }));
      
        console.log("User details updated successfully:", updatedUser); // Log success
        return updatedUser;
      };
      

    const changePassword = async (passwordDetails) => {
        const response = await fetch(`'https://moviereviewclub.colinskinner.me/api/users/change-password/${user.id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(passwordDetails),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || "Error changing password");
        }

        return await response.json();
    };

    return (
        <AuthContext.Provider value={{ isLoggedIn, isAdmin, user, login, logout, updateUserDetails, changePassword }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
