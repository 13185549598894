import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./LoginPage.css"; // Reuse the styles from the login page

const ResetPasswordPage = () => {
    const { token } = useParams();
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const navigate = useNavigate();

    const handlePasswordReset = async (event) => {
        event.preventDefault();

        if (newPassword !== confirmPassword) {
            alert("Passwords do not match");
            return;
        }

        try {
            const response = await fetch("https://moviereviewclub.colinskinner.me/api/reset-password", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ token, newPassword }),
            });

            if (!response.ok) {
                const errorData = await response.json().catch(() => null);
                const errorMessage = errorData ? errorData.message : response.statusText;
                throw new Error(errorMessage);
            }

            const data = await response.json();

            if (data.success) {
                alert("Password reset successful. Please log in with your new password.");
                navigate("/login");
            } else {
                alert("Password reset failed: " + data.message);
            }
        } catch (error) {
            console.error("Password reset error:", error);
            alert("Password reset failed: " + error.message);
        }
    };

    return (
        <div className="login-container">
            <form onSubmit={handlePasswordReset} className="login-form">
                <input
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="New Password"
                    className="login-input"
                    required
                />
                <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirm New Password"
                    className="login-input"
                    required
                />
                <button type="submit" className="login-button">
                    Reset Password
                </button>
            </form>
        </div>
    );
};

export default ResetPasswordPage;
