import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { FaEdit, FaCheck, FaTimes, FaTrash, FaUserShield, FaBan } from "react-icons/fa";
import "./ManageUsers.css";

const ManageUsers = () => {
    const { user, isAdmin } = useAuth();
    const [users, setUsers] = useState([]);
    const [editUserId, setEditUserId] = useState(null);
    const [editName, setEditName] = useState("");

    useEffect(() => {
        console.log("ManageUsers component rendered");
        if (isAdmin) {
            fetch(`https://moviereviewclub.colinskinner.me/api/users?adminId=${user.id}`, {
                headers: { "Content-Type": "application/json" },
            })
            .then(response => {
                console.log("Fetch response status:", response.status);
                return response.json();
            })
            .then(data => {
                console.log("Fetched users data:", data);
                setUsers(data);
            })
            .catch(error => console.error("Error fetching users:", error));
        }
    }, [isAdmin, user.id]);

    const handleEditClick = (userId, currentName) => {
        setEditUserId(userId);
        setEditName(currentName);
    };

    const handleSaveClick = (userId) => {
        fetch(`https://moviereviewclub.colinskinner.me/api/users/${userId}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ real_name: editName }),
        })
        .then(response => response.json())
        .then(() => {
            setUsers(users.map(user => user.id === userId ? { ...user, real_name: editName } : user));
            setEditUserId(null);
        })
        .catch(error => console.error("Error updating user:", error));
    };

    const handleCancelClick = () => {
        setEditUserId(null);
    };

    const handleDisableClick = (userId, currentStatus) => {
        fetch(`https://moviereviewclub.colinskinner.me/api/users/${userId}/toggle-active`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ active: !currentStatus }),
        })
        .then(response => response.json())
        .then(() => {
            setUsers(users.map(user => user.id === userId ? { ...user, active: !currentStatus } : user));
        })
        .catch(error => console.error("Error toggling user status:", error));
    };

    const handleAdminClick = (userId, currentStatus) => {
        fetch(`https://moviereviewclub.colinskinner.me/api/users/${userId}/toggle-admin`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ is_admin: !currentStatus }),
        })
        .then(response => response.json())
        .then(() => {
            setUsers(users.map(user => user.id === userId ? { ...user, is_admin: !currentStatus } : user));
        })
        .catch(error => console.error("Error toggling admin status:", error));
    };

    const handleDeleteClick = (userId, userName) => {
        if (window.confirm(`Are you sure you want to delete ${userName} and all their scores? This action cannot be undone.`)) {
            fetch(`https://moviereviewclub.colinskinner.me/api/users/${userId}`, {
                method: "DELETE",
                headers: { "Content-Type": "application/json" },
            })
            .then(() => {
                setUsers(users.filter(user => user.id !== userId));
            })
            .catch(error => console.error("Error deleting user:", error));
        }
    };

    return (
        <div className="manage-users-container">
            {users.length === 0 ? (
                <p>No users found.</p>
            ) : (
                users.filter(u => u.id !== user.id).map(user => (
                    <div key={user.id} className="user-row">
                        <span>{user.username}</span>
                        <span>{user.email}</span>
                        {editUserId === user.id ? (
                            <>
                                <input
                                    type="text"
                                    value={editName}
                                    onChange={(e) => setEditName(e.target.value)}
                                />
                                <FaCheck onClick={() => handleSaveClick(user.id)} />
                                <FaTimes onClick={handleCancelClick} />
                            </>
                        ) : (
                            <>
                                <span>{user.real_name}</span>
                                <FaEdit onClick={() => handleEditClick(user.id, user.real_name)} />
                            </>
                        )}
                        <FaBan
                            className={user.active ? "" : "highlight"}
                            onClick={() => handleDisableClick(user.id, user.active)}
                        />
                        <FaUserShield
                            className={user.is_admin ? "highlight" : ""}
                            onClick={() => handleAdminClick(user.id, user.is_admin)}
                        />
                        <FaTrash onClick={() => handleDeleteClick(user.id, user.real_name)} />
                    </div>
                ))
            )}
        </div>
    );
};

export default ManageUsers;
