import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./LoginPage.css"; // Reuse the styles from the login page

const RegisterPage = () => {
    const [username, setUsername] = useState("");
    const [realName, setRealName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const navigate = useNavigate();

    const handleRegister = async (event) => {
        event.preventDefault();

        if (password !== confirmPassword) {
            alert("Passwords do not match");
            return;
        }

        try {
            const response = await fetch("https://moviereviewclub.colinskinner.me/api/register", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ username, realName, email, password }),
            });

            const data = await response.json();

            if (data.success) {
                alert("Registration successful! Please check your email to activate your account.");
                navigate("/login");
            } else {
                alert("Registration failed: " + data.message);
            }
        } catch (error) {
            console.error("Registration error:", error);
            alert("Registration failed: " + error.message);
        }
    };

    return (
        <div className="login-container">
            <form onSubmit={handleRegister} className="login-form">
                <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Username" className="login-input" required />
                <input type="text" value={realName} onChange={(e) => setRealName(e.target.value)} placeholder="Real Name" className="login-input" required />
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" className="login-input" required />
                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" className="login-input" required />
                <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Confirm Password" className="login-input" required />
                <button type="submit" className="login-button">
                    Register
                </button>
            </form>
        </div>
    );
};

export default RegisterPage;
