import React, { useState, useEffect } from "react";
import styles from "./StarRating.module.css"; // Import the CSS module

const StarRating = ({ count = 0, onChange, size = 24, readOnly = false }) => {
  const [rating, setRating] = useState(count);

  useEffect(() => {
    setRating(count); // Update local rating if count changes
  }, [count]);

  const handleChange = (newRating, event) => {
    if (readOnly) return; // Do nothing if readOnly is true
    event.stopPropagation(); // Prevents the click from affecting parent elements
    let rect = event.target.getBoundingClientRect();
    let isHalf = event.clientX - rect.left < rect.width / 2; // Check if the click is on the left half of the star
    let finalRating = isHalf ? newRating - 0.5 : newRating; // Adjust rating accordingly
    setRating(finalRating);
    onChange(finalRating); // Send the updated rating
  };

  return (
    <div className={styles.starRating}>
      {[...Array(5)].map((_, index) => {
        const starValue = index + 1;
        let starClass = styles.off;
        if (starValue <= Math.ceil(rating)) {
          starClass = starValue <= rating ? styles.on : styles.half;
        }
        return (
          <button
            type="button"
            key={starValue}
            className={`${styles.star} ${starClass}`}
            onClick={(e) => handleChange(starValue, e)}
            style={{ fontSize: size }}
            disabled={readOnly}
          >
            ★
          </button>
        );
      })}
    </div>
  );
};

export default StarRating;
