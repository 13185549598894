import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';  // Make sure the path is correct

const LogoutButton = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate('/login');  // Or wherever you want to redirect the user post-logout
    };

    return (
        <button onClick={handleLogout}>Logout</button>
    );
};

export default LogoutButton;
