// AddMovie.js
import React, { useState, useEffect } from "react";
import './AddMovie.css';

const AddMovie = () => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [year, setYear] = useState("");
    const [posterUrl, setPosterUrl] = useState("");
    const [movies, setMovies] = useState([]);
    const [editMovie, setEditMovie] = useState(null);

    useEffect(() => {
        fetch("https://moviereviewclub.colinskinner.me/api/movies")
            .then(response => response.json())
            .then(data => setMovies(data))
            .catch(error => console.error("Error fetching movies:", error));
    }, []);

    const handleAddSubmit = async (event) => {
        event.preventDefault();
        const movieData = { title, description, year, posterUrl };
        fetch("https://moviereviewclub.colinskinner.me/api/movies", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(movieData),
        })
        .then(response => {
            if (response.ok) {
                alert("Movie added successfully!");
                setMovies([movieData, ...movies]);
                setTitle("");
                setDescription("");
                setYear("");
                setPosterUrl("");
            } else {
                alert("Failed to add movie");
            }
        })
        .catch(error => {
            console.error("Error:", error);
        });
    };

    const handleEditClick = (movie) => {
        if (editMovie && editMovie.id === movie.id) {
            setEditMovie(null);
        } else {
            setEditMovie(movie);
        }
    };

    const handleUpdateSubmit = async (event) => {
        event.preventDefault();
        const movieData = { title: editMovie.title, description: editMovie.description, year: editMovie.year, posterUrl: editMovie.poster_url };
        fetch(`https://moviereviewclub.colinskinner.me/api/movies/${editMovie.id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(movieData),
        })
        .then(response => {
            if (response.ok) {
                alert("Movie updated successfully!");
                setMovies(movies.map(movie => (movie.id === editMovie.id ? editMovie : movie)));
                setEditMovie(null);
            } else {
                alert("Failed to update movie");
            }
        })
        .catch(error => {
            console.error("Error:", error);
        });
    };

    return (
        <div className="add-movie-container">
            <h1>Add New Movie</h1>
            <form onSubmit={handleAddSubmit} className="add-movie-form">
                <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Title" required />
                <textarea value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Description" required />
                <input type="text" value={year} onChange={(e) => setYear(e.target.value)} placeholder="Year" required />
                <input type="text" value={posterUrl} onChange={(e) => setPosterUrl(e.target.value)} placeholder="Poster URL" required />
                <button type="submit">Add Movie</button>
            </form>

            <div className="movie-list">
                {movies.map(movie => (
                    <div key={movie.id} className="movie-list-item">
                        <span>{movie.title}</span>
                        <button 
                            onClick={() => handleEditClick(movie)} 
                            className={editMovie && editMovie.id === movie.id ? "close-edit" : ""}
                        >
                            {editMovie && editMovie.id === movie.id ? "Close Edit" : "Edit"}
                        </button>
                        {editMovie && editMovie.id === movie.id && (
                            <div className="edit-movie-section">
                                <h2>Edit Movie</h2>
                                <form onSubmit={handleUpdateSubmit} className="edit-movie-form">
                                    <input type="text" value={editMovie.title} onChange={(e) => setEditMovie({...editMovie, title: e.target.value})} placeholder="Title" required />
                                    <textarea value={editMovie.description} onChange={(e) => setEditMovie({...editMovie, description: e.target.value})} placeholder="Description" required />
                                    <input type="text" value={editMovie.year} onChange={(e) => setEditMovie({...editMovie, year: e.target.value})} placeholder="Year" required />
                                    <input type="text" value={editMovie.poster_url} onChange={(e) => setEditMovie({...editMovie, poster_url: e.target.value})} placeholder="Poster URL" required />
                                    <button type="submit">Update Movie</button>
                                </form>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AddMovie;
