import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./LoginPage.css"; // Reuse the styles from the login page

const ForgotPasswordPage = () => {
    const [identifier, setIdentifier] = useState("");
    const navigate = useNavigate();

    const handlePasswordReset = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch("https://moviereviewclub.colinskinner.me/api/forgot-password", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ identifier }),
            });

            if (!response.ok) {
                const errorData = await response.json().catch(() => null);
                const errorMessage = errorData ? errorData.message : response.statusText;
                throw new Error(errorMessage);
            }

            const data = await response.json();

            if (data.success) {
                alert("Please check your email for password reset instructions.");
                navigate("/login");
            } else {
                alert("Password reset failed: " + data.message);
            }
        } catch (error) {
            console.error("Password reset error:", error);
            alert("Password reset failed: " + error.message);
        }
    };

    return (
        <div className="login-container">
            <form onSubmit={handlePasswordReset} className="login-form">
                <input
                    type="text"
                    value={identifier}
                    onChange={(e) => setIdentifier(e.target.value)}
                    placeholder="Username or Email"
                    className="login-input"
                    required
                />
                <button type="submit" className="login-button">
                    Reset Password
                </button>
            </form>
        </div>
    );
};

export default ForgotPasswordPage;
