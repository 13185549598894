import React, { useState, useEffect } from "react";
import MovieCard from "./MovieCard";
import { useLocation } from "react-router-dom";
import "./MovieCardsPage.css";
import { useAuth } from "../contexts/AuthContext";

const MovieCardsPage = () => {
    const { isLoggedIn, isAdmin, user } = useAuth();
    const [movies, setMovies] = useState([]);
    const [overlayActive, setOverlayActive] = useState(false);
    const location = useLocation();
    const [activeMovieId, setActiveMovieId] = useState(null); // Keep track of the active movie

    useEffect(() => {
        if (user?.id) {
            fetch(`https://moviereviewclub.colinskinner.me/api/movies?userId=${user.id}`, {
                headers: { "Content-Type": "application/json" },
            })
            .then(response => response.json())
            .then(data => {
                console.log("Fetched movies:", data); // Log the fetched data
                setMovies(data); // Set the fetched movies
            })
            .catch(error => console.error("Error fetching movies:", error));
        }
    }, [user?.id]);

    const updateMovieRating = (movieId, rating, userId) => {
        fetch("https://moviereviewclub.colinskinner.me/api/rate", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ userId, movieId, score: rating }),
        })
        .then(response => response.json())
        .then(data => {
            console.log("Rating update successful:", data.message);
            // Refresh movies list after updating rating
            setMovies(prevMovies => 
                prevMovies.map(movie =>
                    movie.id === movieId
                        ? {
                            ...movie,
                            scores: movie.scores.map(score =>
                                score.user_id === userId ? { ...score, score: rating } : score
                            ),
                        }
                        : movie
                )
            );
        })
        .catch(error => console.error("Error updating movie rating:", error));
    };

    const updateMovieRevealStatus = (movieId, isRevealed) => {
        fetch("https://moviereviewclub.colinskinner.me/api/movies/reveal", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ movieId, isRevealed }),
        })
        .then(response => response.json())
        .then(data => {
            console.log("Reveal status update successful:", data.message);
            // Refresh movies list after updating reveal status
            fetch(`https://moviereviewclub.colinskinner.me/api/movies?userId=${user.id}`, {
                headers: { "Content-Type": "application/json" },
            })
            .then(response => response.json())
            .then(data => {
                console.log("Fetched movies:", data); // Log the fetched data
                setMovies(data);
            })
            .catch(error => console.error("Error fetching movies:", error));
        })
        .catch(error => console.error("Error updating reveal status:", error));
    };

    const handleOverlayClick = (event) => {
        if (event.target.classList.contains('overlay')) {
            setActiveMovieId(null);
            setOverlayActive(false);
        }
    };

    const handleCardClick = (movieId) => {
        setActiveMovieId((prevId) => {
            const newActiveId = prevId === movieId ? null : movieId;
            setOverlayActive(newActiveId !== null);
            return newActiveId;
        });
    };

    const handleRevealScores = (movieId, isRevealed) => {
        if (isRevealed === 1) {
            setActiveMovieId(movieId);
            setOverlayActive(true);
        }
        updateMovieRevealStatus(movieId, isRevealed);
    };

    if (!isLoggedIn || location.pathname === "/login") return null;

    return (
        <>
            <div className={`overlay ${overlayActive ? 'active' : ''}`} onClick={handleOverlayClick}></div>
            <div className="movie-cards-container">
                {movies.map(movie => (
                    <MovieCard
                        key={movie.id} // Ensure each key is unique
                        movie={movie}
                        userRating={movie.scores.find(score => score.user_id === user.id)?.score || 0}
                        onUpdateRating={updateMovieRating}
                        onFlip={() => handleCardClick(movie.id)}
                        isAdmin={isAdmin}
                        onRevealStatusChange={handleRevealScores}
                        isActive={activeMovieId === movie.id} // Pass active state
                    />
                ))}
            </div>
        </>
    );
};

export default MovieCardsPage;
