// LoginPage.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./LoginPage.css";
import { useAuth } from "../contexts/AuthContext"; // Adjust the path as necessary

const LoginPage = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const { login } = useAuth();
    const navigate = useNavigate();

    const handleLogin = async (event) => {
        event.preventDefault();

        try {
            await login(username, password);
            navigate("/movies");
        } catch (error) {
            console.error("Login error:", error);
            alert("Login failed: " + error.message);
        }
    };

    return (
        <div className="login-container">
            <form onSubmit={handleLogin} className="login-form">
                <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Username" className="login-input" required />
                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" className="login-input" required />
                <button type="submit" className="login-button">
                    Login
                </button>
                <button type="button" className="login-button" onClick={() => navigate("/register")}>
                    Make New User
                </button>
                <a href="/forgot-password" className="forgot-password-link">Forgot Password?</a>
            </form>
        </div>
    );
};

export default LoginPage;
